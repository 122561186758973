import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

export default function SchemaDisplay(props) {
    const images = props.schemas.map(schema => schema.image.data)
        .map(img => {
            return {
                original: 'data:image/jpeg;base64,' + img,
                thumbnail: 'data:image/jpeg;base64,' + img
            }
        })


    if (images.length === 0) return null;

    return (
        <ImageGallery items={images} showPlayButton={false} showNav={false}/>
    )
}
