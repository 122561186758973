import React from "react"
import SchemaDisplay from "../components/schema/SchemaDisplay";
import SpareListDisplay from "../components/schema/SparesListDisplay";
import {Layout} from "../components/common/Layout";
import {MetaHeader} from "../components/common/MetaHeader";

const SchemaPage = ({pageContext: {data}}) => {
    return (
        <Layout>
            <div className="grid grid-nogutter">
                <h1>Запчастини до Metabo {data.tool.name} ({data.tool.model})</h1>
                <div className="col-12">
                    <SchemaDisplay model={data.tool.model} schemas={data.schemas}/>
                    <div className="col-12">
                        <SpareListDisplay model={data.tool.model} spares={data.list} name={data.tool.name}/>
                    </div>

                </div>
            </div>
        </Layout>
    )
}
export const Head = ({pageContext}) => (
    <MetaHeader prefixes={
        {
            title: `Запчастини до Metabo ${pageContext.data.tool.name} (${pageContext.data.tool.model})`,
            description: `Запчастини для Metabo ${pageContext.data.tool.name} (${pageContext.data.tool.model})`,
            keywords: `ремонт Metabo ${pageContext.data.tool.name} (${pageContext.data.tool.model}), сервіс Metabo ${pageContext.data.tool.name} (${pageContext.data.tool.model}), запчастини ${pageContext.data.tool.name} (${pageContext.data.tool.model})`
        }


    }/>
)
export default SchemaPage
