import React, { useRef } from 'react';

import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { displayModel, displayName, displayPosition, displayProductPrice } from "../../service/TableDisplayService";
import AddToCart from "../common/AddToCart";

const SpareListDisplay = (props) => {
    const [spares, setSpares] = React.useState([])
    const [filterPosition, setFilterPosition] = React.useState([]);
    const toast = useRef(null);

    React.useEffect(() => {
        const isCartHasProducts = props.cartProducts && props.cartProducts.length > 0
        const spares = props.spares.map(product => {
            let cartProduct = isCartHasProducts ? props.cartProducts.find(item => item.product.spare === product.spare) : undefined;
            let listQtyField = cartProduct ? { listQty: cartProduct.count } : { "listQty": product.listQty ? product.listQty : convertStringToQuantity(product.quantity) };
            let orderField = cartProduct ? { "cartQty": cartProduct.count } : undefined;

            return { ...product, ...listQtyField, ...orderField }
        })

        if (filterPosition === null || filterPosition.length < 1) setSpares(spares)
        else {
            let arr = spares.filter(spare => spare.position.startsWith(filterPosition))
            setSpares(arr)
        }
    }, [filterPosition, props.spares, props.cartProducts])

    const convertStringToQuantity = (quantity) => {
        const qty = parseInt(quantity)
        return qty < 1 ? 1 : qty
    }

    const displayHeader = (
        <div className="field p-fluid">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setFilterPosition(e.target.value)}
                    placeholder="Фільтрувати за номером на схемі" />
            </span>
        </div>
    );

    const displayNeededQuantity = (product) => {
        return (
            <>
                <span className="hidden text-bold small">Кількість на схемі</span> {product.quantity}
            </>
        )
    }

    const addToCartButtons = (product) => {
        return (
            <AddToCart productLine={product} />
        )
    }

    const renderHeader = (name, model) => {
        return (
            <>
                Перелік запчастин для Metabo {name} {model}
            </>
        )
    }
    return (
        <>
            <Toast ref={toast} />
            <h2>{renderHeader(props.name, props.model)}</h2>
            <DataTable value={spares}
                className="schema-details"
                tableClassName="padding-left-1"
                header={displayHeader}
                autoLayout={true}
                sortField="position" sortOrder={1}
                dataKey="spare"
                paginator rows={10}>
                <Column field="position" header="Номер на схемі"
                    filterPlaceholder="Фільтрувати за номером на схемі" body={displayPosition} />
                <Column header="Артикул" body={displayModel} />
                <Column header="Назва" body={displayName} />
                <Column header="Ціна" body={displayProductPrice} />
                <Column header="Кількість на схемі" body={displayNeededQuantity} />
                <Column header="Додати до кошика" body={addToCartButtons} />
            </DataTable>
        </>
    );
}

export default SpareListDisplay
